import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import { ApsimService } from '../apsim/apsim.service';
import { ColumnEnums } from './column.enums';
import { ResultModel } from './result.model';
import { ApsimModel } from '../apsim/apsim.model';
import * as $ from 'jquery';
import { TypeConverter } from '../../../common/utility/typeConverter.service';
import { Comparator } from '../../../common/utility/comparator.service';
import { YearlyValue } from '../../../common/models/yearlyValue.model';

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.css']
})
export class BarGraphComponent implements OnInit {

  constructor(private apsimService: ApsimService) { }

  /* Declaring the required variables as a private accessor */
  public isOutFileStringEmpty = true;
  public loading: boolean;
  public apsimModels: ApsimModel[];
  public dates: string[] = [];
  private months = ['January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private groups: string[];
  private margin;
  private width;
  private height;
  private radius = 9;

  color;
  /**
   *  This function maps the crop growth years to actual years and then maps the actual years to the input result.
   */
  public static yearToModelResultMapping(result: string[], startDay: number, startMonth: number,
    terminationDay: number, terminationMonth: number,
    dates: string[], column: ColumnEnums) {
    let startYear = parseInt(dates[0].split('/')[2], 10);
    let startDate = new Date(startYear, startMonth, startDay);

    const yearToResults = {} as { year: number, result: number[] };

    /*  Set the termination year based on selected termination month.  */
    let terminationYear: number;
    let terminationDate: Date;
    if (startMonth < terminationMonth) {
      terminationYear = startYear;
    } else {
      terminationYear = startYear + 1;
    }

    /*  For evaporation only, we look at a complete year in .out file */
    if (column === ColumnEnums.SOIL_EVAPORATION) {
      terminationMonth = startMonth;
      terminationDay = startDay - 1;
      terminationYear = startYear + 1;
      terminationDate = new Date(terminationYear, terminationMonth, terminationDay);
    } else {
      terminationDate = new Date(terminationYear, terminationMonth, terminationDay);
    }

    yearToResults[terminationYear] = [];
    dates.forEach((date, index) => {
      const d = date.split('/');
      const currentYear = parseInt(d[2], 10);
      const currentMonth = parseInt(d[1], 10);
      const currentDay = parseInt(d[0], 10);

      /* Need to subtract 1 from the month because of Date indexing !! */
      const currentDate = new Date(currentYear, currentMonth - 1, currentDay);

      if (currentDate >= startDate && currentDate <= terminationDate) {
        yearToResults[terminationYear].push(parseFloat(result[index]));

        if (currentDate.toDateString() === terminationDate.toDateString()) {
          startYear = startYear + 1;
          terminationYear = terminationYear + 1;
          startDate = new Date(startYear, startMonth, startDay);
          terminationDate = new Date(terminationYear, terminationMonth, terminationDay);
          yearToResults[terminationYear] = [];
        }
      }
    });

    return yearToResults;
  }

  /**
   * For the column in the parameter, Get the max data for each year, remove all the zeroes and sorts the data in ascending order
   * return the max data for each year
   */
  public static getAdjustedYearly_Max(yearlyData: { year: number, result: number[] }[]) {
    const sortedYearlyMax = [];

    yearlyData.forEach((simulation, index) => {
      const yearlyMax = [];
      d3.keys(simulation).forEach(year => {
        const maxVal = Number(d3.max(yearlyData[index][year]));
        if (maxVal !== 0) {
          yearlyMax.push(new YearlyValue(year, maxVal, index));
        }
      });
      yearlyMax.sort(Comparator.compareValue);
      yearlyMax.forEach(element => {
        sortedYearlyMax.push(element);
      });
    });
    return sortedYearlyMax;
  }

  /**
   * For the given column, get the sum of data for each year
   * remove all the zeroes and sorts the data in ascending order
   */
  public static getAdjustedYearly_Sum(yearlyData: { year: number, result: number[] }[]) {
    const sortedYearlySum = [];

    yearlyData.forEach((simulation, index) => {
      const yearlySum = [];
      d3.keys(simulation).forEach(year => {
        const sumVal = Number(d3.sum(yearlyData[index][year]));
        if (sumVal !== 0) {
          yearlySum.push(new YearlyValue(year, sumVal, index));
        }
      });
      yearlySum.sort(Comparator.compareValue);
      yearlySum.forEach(element => {
        sortedYearlySum.push(element);
      });
    });

    return sortedYearlySum;
  }

  ngOnInit() {
    d3Tip(d3);
    /* Set the dimensions for the graph. */
    this.margin = { top: 10, right: 30, bottom: 100, left: 60 };
    this.width = 800 - this.margin.left - this.margin.right;
    this.height = 570 - this.margin.top - this.margin.bottom;
    this.apsimService.apsimModels.subscribe((apsimModels: ApsimModel[]) => {
      this.apsimModels = [];
      Object.assign(this.apsimModels, apsimModels);
      this.groups = [];
      this.apsimModels.forEach((model) => {
        const groupLabel = model.startMonth + '/' + model.startDay + '-' + model.terminationMonth + '/' + model.terminationDay;
        this.groups.push(groupLabel);
      }
      );
    });
    this.apsimService.loading.subscribe((loading) => {
      this.loading = loading;
      $('.bars .wrapper').toggleClass('disabled');
    });

    this.apsimService.resultModels.subscribe(
      (resultModels: ResultModel[]) => {
        this.isOutFileStringEmpty = false;
        this.dates = TypeConverter.convertStringToArray(resultModels[0].date);
        this.drawGraphs(resultModels);
      }
    );

  }

  /**
   * Draw all of the required bar charts
   */
  private drawGraphs(resultModels: ResultModel[]) {
    this.drawBioMassBarGraph(resultModels.map(x => x.biomass));
    this.drawTranspirationBarGraph(resultModels.map(x => x.transpiration));
    this.drawNTotalUptakeBarGraph(resultModels.map(x => x.nitrogenUptake));

  }

  /**
   * Draw the BioMass Graph.
   */
  private drawBioMassBarGraph(biomasses: string[]) {
    const biomassDataArr: string[][] = [];
    const biomassYearlyArr = [];
    let biomassYearlyMax: YearlyValue[];

    biomasses.forEach(biomass => biomassDataArr.push(TypeConverter.convertStringToArray(biomass)));
    biomassDataArr.forEach((biomassData, simIndex) => {
      const startDay = parseInt(this.apsimModels[simIndex].startDay, 10);
      const startMonth = this.months.indexOf(this.apsimModels[simIndex].startMonth);
      const terminationDay = parseInt(this.apsimModels[simIndex].terminationDay, 10);
      const terminationMonth = this.months.indexOf(this.apsimModels[simIndex].terminationMonth);
      biomassYearlyArr.push(
        BarGraphComponent.yearToModelResultMapping(biomassData,
          startDay, startMonth,
          terminationDay, terminationMonth,
          this.dates, ColumnEnums.RYE_BIOMASS)
      );
    });
    biomassYearlyMax = BarGraphComponent.getAdjustedYearly_Max(biomassYearlyArr);

    this.loadFeaturesInSVG(ColumnEnums.RYE_BIOMASS, biomassYearlyMax, 'blue');
  }

  /**
   * Draw the Transpiration Graph.
   */
  private drawTranspirationBarGraph(transpirations: string[]) {

    const transpirationDataArr: string[][] = [];
    const transpirationYearlyArr = [];
    let transpirationYearlySum: YearlyValue[];

    transpirations.forEach(transpiration => transpirationDataArr.push(TypeConverter.convertStringToArray(transpiration)));
    transpirationDataArr.forEach((transpirationData, simIndex) => {
      const startDay = parseInt(this.apsimModels[simIndex].startDay, 10);
      const startMonth = this.months.indexOf(this.apsimModels[simIndex].startMonth);
      const terminationDay = parseInt(this.apsimModels[simIndex].terminationDay, 10);
      const terminationMonth = this.months.indexOf(this.apsimModels[simIndex].terminationMonth);
      transpirationYearlyArr.push(
        BarGraphComponent.yearToModelResultMapping(transpirationData,
          startDay, startMonth,
          terminationDay, terminationMonth,
          this.dates, ColumnEnums.TRANSPIRATION)
      );
    });
    transpirationYearlySum = BarGraphComponent.getAdjustedYearly_Sum(transpirationYearlyArr);

    this.loadFeaturesInSVG(ColumnEnums.TRANSPIRATION, transpirationYearlySum, 'green');

  }

  /**
   * Draw the Nitrogen Uptake Graph.
   */
  private drawNTotalUptakeBarGraph(nUptakes: string[]) {

    const nUptakeDataArr: string[][] = [];
    const nUptakeYearlyArr = [];
    let nUptakeYearlyMax: YearlyValue[];

    nUptakes.forEach(biomass => nUptakeDataArr.push(TypeConverter.convertStringToArray(biomass)));
    nUptakeDataArr.forEach((nUptakeData, simIndex) => {
      const startDay = parseInt(this.apsimModels[simIndex].startDay, 10);
      const startMonth = this.months.indexOf(this.apsimModels[simIndex].startMonth);
      const terminationDay = parseInt(this.apsimModels[simIndex].terminationDay, 10);
      const terminationMonth = this.months.indexOf(this.apsimModels[simIndex].terminationMonth);
      nUptakeYearlyArr.push(
        BarGraphComponent.yearToModelResultMapping(nUptakeData,
          startDay, startMonth,
          terminationDay, terminationMonth,
          this.dates, ColumnEnums.NITROGEN_UPTAKE)
      );
    });
    nUptakeYearlyMax = BarGraphComponent.getAdjustedYearly_Max(nUptakeYearlyArr);

    this.loadFeaturesInSVG(ColumnEnums.NITROGEN_UPTAKE, nUptakeYearlyMax, 'red');
  }

  /**
   * Reusable SVG component
   */
  private loadFeaturesInSVG(component: ColumnEnums, yearlyTotal: YearlyValue[], colorSelection: string) {

    const maxX = Number(d3.max(yearlyTotal.map(d => d.value)));

    let chart; let xLabel;
    if (component === ColumnEnums.RYE_BIOMASS) {
      chart = '#biomass';
      xLabel = 'Biomass (lbm/ac)';
    } else if (component === ColumnEnums.TRANSPIRATION) {
      chart = '#transpiration';
      xLabel = 'Transpiration (inches)';
    } else if (component === ColumnEnums.NITROGEN_UPTAKE) {
      chart = '#nitrogen_uptake';
      xLabel = 'Nitrogen Uptake (lbm/ac)';
    }

    d3.select(chart)
      .select('g')
      .remove();

    const svg = d3.select(chart)
      .attr('viewBox', '0 -25 900 590')
      .classed('svg-content-responsive', true);

    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    const x = d3.scaleLinear()
      .range([0, this.width])
      .domain([0, maxX + (0.20 * maxX)]);
    g.append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(x));

    if (colorSelection === 'blue') {
      this.color = this.getColors();
    }
    if (colorSelection === 'green') {
      this.color = this.getColors2();
    }
    if (colorSelection === 'red') {
      this.color = this.getColors3();
    }
    let orderedYears = [];
    const years = [];
    yearlyTotal.forEach(d => {
      if (!orderedYears.includes(d.year)) {
        orderedYears.push(d.year);
      } else {
        years.push(orderedYears);
        orderedYears = [d.year];
      }
    });
    years.push(orderedYears);

    const y: any[] = [];
    years.forEach(orderYears => {
      const Y = d3.scaleBand()
        .range([10, this.height])
        .domain(Array.from(orderYears));

      g.append('g')
        .call(
          d3.axisLeft(Y)
            .tickSize(0)
            .tickFormat(d => '')
        );

      y.push(Y);
    });

    /* Tool tip */
    const tooltip = d3Tip()
      .attr('class', 'd3-tip')
      .html(d => {
        return component + ': ' + Math.round(d.value) + '<br>';
      });
    g.call(tooltip);

    /* Add X label */
    g.append('text')
      .attr('y', this.height + 50)
      .attr('x', (this.width / 2))
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .style('font-size', '17px')
      .text(xLabel);

    /* Add y label */
    g.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - this.margin.left + 15)
      .attr('x', 0 - (this.height / 2))
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .style('font-size', '17px')
      .text('Potential Outcomes');

    /* Adding legend */
    const legend = g.selectAll('.legend')
      .data(this.groups)
      .enter()
      .append('g');

    legend.append('rect')
      // @ts-ignore
      .attr('fill', this.color)
      .attr('width', 20)
      .attr('height', 20)
      // tslint:disable-next-line:only-arrow-functions
      .attr('y', function (d, i) {
        return i * 25;
      })
      .attr('x', this.width);

    const self = this;
    legend.append('text')
      .attr('class', 'label')
      // tslint:disable-next-line:only-arrow-functions
      .attr('y', function (d, i) {
        return i * 25 + 13;
      })
      .attr('x', this.width + 40)
      .attr('text-anchor', 'start')
      .style('font-size', '17px')
      // tslint:disable-next-line:only-arrow-functions
      .text(function (d, i) {
        return self.groups[i];
      });

    /* Adding dots in the chart */
    g.selectAll('.dot')
      .data(yearlyTotal)
      .enter().append('circle')
      .attr('class', 'dot')
      .attr('cx', d => x(d.value))
      .attr('cy', d => y[d.simulation](d.year))
      .attr('r', this.radius)
      // @ts-ignore
      .attr('fill', d => this.color(d.simulation))
      .on('mouseover', tooltip.show)
      .on('mouseout', tooltip.hide);

    if (this.apsimModels.length === 1) {

      const avgX = Number(d3.mean(yearlyTotal.map(d => d.value)));
      const twentyFifthPercentile = Number(d3.quantile(yearlyTotal.map(d => d.value), 0.75));
      const seventyFifthPercentile = Number(d3.quantile(yearlyTotal.map(d => d.value), 0.25));

      /* Add the 25% percentile line*/
      g.append('line')
        .attr('x1', x(twentyFifthPercentile))
        .attr('y1', 5)
        .attr('x2', x(twentyFifthPercentile))
        .attr('y2', (this.height))
        .attr('stroke-dasharray', '5,5')
        .attr('stroke', 'gray');

      /* Adding line info */
      g.selectAll('threshold')
        .data([twentyFifthPercentile])
        .enter().append('text')
        .attr('class', 'threshold')
        .attr('transform', d => 'rotate(-0,' + x(d) + ',' + 25 + ')')
        .attr('text-anchor', 'end')
        .attr('x', d => x(d) + 0)
        .attr('y', -15)
        .style('font-size', '10px')
        .text('25 percentile')
        .append('tspan')
        .attr('x', d => x(d) + 0)
        .attr('y', -2)
        .style('font-size', '10px')
        // .text(d => Math.round(d));
        .text(d => Math.floor(d));

      /* Add the average line */
      g.append('line')
        .attr('x1', x(avgX))
        .attr('y1', 5)
        .attr('x2', x(avgX))
        .attr('y2', (this.height))
        .attr('stroke-dasharray', '5,5')
        .attr('stroke', 'gray');

      /* Adding line info */
      g.selectAll('threshold')
        .data([avgX])
        .enter().append('text')
        .attr('class', 'threshold')
        .attr('transform', d => 'rotate(-0,' + x(d) + ',' + 25 + ')')
        .attr('text-anchor', 'end')
        .attr('x', d => x(d) - 5)
        .attr('y', -15)
        .text('Avg')
        .append('tspan')
        .attr('x', d => x(d) - 5)
        .attr('y', -2)
        // .text(d => Math.round(d));
        .text(d => Math.floor(d));

      /* Add the 75% percentile line*/
      g.append('line')
        .attr('x1', x(seventyFifthPercentile))
        .attr('y1', 5)
        .attr('x2', x(seventyFifthPercentile))
        .attr('y2', (this.height))
        .attr('stroke-dasharray', '5,5')
        .attr('stroke', 'gray');

      /* Adding line info */
      g.selectAll('threshold')
        .data([seventyFifthPercentile])
        .enter().append('text')
        .attr('class', 'threshold')
        .attr('transform', d => 'rotate(-0,' + x(d) + ',' + 25 + ')')
        .attr('text-anchor', 'start')
        .attr('x', d => x(d) + 5)
        .attr('y', -15)
        .text('75 percentile')
        .append('tspan')
        .attr('x', d => x(d) + 5)
        .attr('y', -2)
        // .text(d => Math.round(d));
        .text(d => Math.floor(d));
    } else {
      const simulations = new Set();
      yearlyTotal.forEach(c => simulations.add(c.simulation));

      simulations.forEach(s => {
        const avgX = d3.mean(yearlyTotal.filter(f => f.simulation === s).map(c => c.value));

        /* Add the average line */
        g.append('line')
          .datum(s)
          .attr('x1', x(avgX))
          .attr('y1', 5)
          .attr('x2', x(avgX))
          .attr('y2', (this.height))
          .attr('stroke-dasharray', '5,5')
          // @ts-ignore
          .attr('stroke', d => this.color(d));

        /* Adding line info */
        g.selectAll('threshold')
          .data([avgX])
          .enter().append('text')
          .attr('class', 'threshold')
          .attr('transform', d => 'rotate(-0,' + x(d) + ',' + 25 + ')')
          .attr('text-anchor', 'middle')
          .attr('x', d => x(d) + 5)
          .attr('y', -15)
          .text('Avg')
          .append('tspan')
          .attr('x', d => x(d) + 5)
          .attr('y', -2)
          .text(d => Math.round(d));

      });
    }

  }

  /**
   * Get Color element
   */
  private getColors() {
    const colors: string[] = ['#90add2', '#824482', '#904242'];
    const color = d3.scaleOrdinal()
      .domain(this.groups)
      .range(colors.slice(0, this.groups.length));

    return color;
  }
  private getColors2() {
    const colors: string[] = ['#96be25', '#824482', '#904242'];
    const color = d3.scaleOrdinal()
      .domain(this.groups)
      .range(colors.slice(0, this.groups.length));

    return color;
  }
  private getColors3() {
    const colors: string[] = ['#904242', '#824482', '#904242'];
    const color = d3.scaleOrdinal()
      .domain(this.groups)
      .range(colors.slice(0, this.groups.length));

    return color;
  }
}

