export class YearlyValue {
  year: any;
  value: any;
  simulation: any;

  constructor(year, value, simulation) {
    this.year =  year;
    this.value = value;
    this.simulation = simulation;
  }
}
