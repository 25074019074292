export class DaysToWeeksConverter {
  private static maxWeek = 4;
  /**
   * This function converts days to respective weeks
   */
  public static convertToWeeks(day: number ) {
    const week = Math.ceil(day / 7.0);
    return week > this.maxWeek ? this.maxWeek : week;
  }
}
