export class TypeConverter {

  /**
   * This function converts long Strings separated by Semi-Colon (;) to String Array
   */
  public static convertStringToArray(str: string ) {
    return str != null ? str.split(';') : [];
  }

  /**
   * This function converts cover crop Strings to a numeric representation
   */
  public static convertCoverCropStringToNumericRepresentation(coverCrop: string) {
    switch (coverCrop) {
      case '':
        return 0;
      case 'Rye':
        return 1;
      case ' ':
        return 2;
    }
  }

  /**
   * This function converts metfile Strings to a numeric representation
   */
  public static convertMetFileStringToNumericRepresentation(metFile: string) {
    switch (metFile) {
      case '':
        return 0;
      case 'Northwest Nebraska 43 103':
        return 1;
      case 'North Central Nebraska 43 101':
        return 2;
      case 'North Central Nebraska 43 99':
        return 3;
      case 'Northeast Nebraska 43 97':
        return 4;
      case 'Southwest Nebraska 41 103':
        return 5;
      case 'Southwest Nebraska 41 101':
        return 6;
      case 'Central Nebraska 41 99':
        return 7;
      case 'Southeast Nebraska 41 97':
        return 8;
      case 'Southeast Nebraska 41 95':
        return 9;
      case ' ' :
        return 10;
    }
  }

  /**
   * This function converts soil profiles Strings to a numeric representation
   */
  public static convertSoilProfileStringToNumericRepresentation(soilType: string) {
    switch (soilType) {
      case '':
        return 0;
      case 'Crete Silt Loam No3824':
        return 1;
      case 'Sandy loam No200':
        return 2;
      case 'Wabash Silty Clay Loam':
        return 3;
      case 'Thurman Crofton Sand':
        return 4;
      case ' ':
        return 5;
    }
  }

  /**
   * This function converts String Start months to numeric representation
   */
  public static convertStringStartMonthsToNumericRepresentation(month: string) {
    switch (month) {
      case '':
        return 7;
      case 'August':
        return 8;
      case 'September':
        return 9;
      case 'October':
        return 10;
      case 'November':
        return 11;
      case ' ':
        return 12;
    }
  }

  /**
   * This function converts String Termination months to numeric representation
   */
  public static convertStringTerminationMonthsToNumericRepresentation(month: string) {
    switch (month) {
      case '':
        return 2;
      case 'March':
        return 3;
      case 'April':
        return 4;
      case 'May':
        return 5;
      case 'June':
        return 6;
      case ' ':
        return 7;
    }
  }

  /**
   * This function converts String weeks to numeric representation
   */
  public static convertWeeksStringToNumericRepresentation(week: string) {
    switch (week) {
      case '':
        return 0;
      case 'First':
        return 1;
      case 'Second':
        return 2;
      case 'Third':
        return 3;
      case 'Fourth':
        return 4;
      case ' ':
        return 5;
    }
  }

}
