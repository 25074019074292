import { Component } from '@angular/core';


@Component({
  selector: '#cover-crop-decision-tool',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ne-covercrops';
}
