export class Comparator {
  /**
   *  This function orders the elements in ascending order based on value
   */
  public static compareValue(a, b) {
    if (a.value < b.value) {
      return 1;
    }
    if (a.value > b.value) {
      return -1;
    }
    return 0;
  }
}
