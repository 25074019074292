import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    // tslint:disable-next-line:only-arrow-functions
    $(document).ready(function() {

      /* Forces window to scroll to top on page load */
      $(window).scrollTop(0);

      // tslint:disable-next-line:only-arrow-functions
      $(window).scroll(function() {
        const mousePosition = $(window).scrollTop();
        const navBarHeaderHeight = $('header').height();
        const faqHeaderHeight = $('.faq-header').height();
        $('.section').each(function(i) {
          const element = $(this)[0];
          // @ts-ignore
          const offT = element.offsetTop;
          if (mousePosition >= offT - 2 * (faqHeaderHeight + navBarHeaderHeight)) {
            $('.focused').removeClass('focused');
            $('.highlight').removeClass('highlight');
            const className = element.id;
            $('.' + className).children().addClass('focused');
            $(this).addClass('highlight');
          }
        });

      });

      function showAnswers(event) {
        const target = $(event.target);
        target.toggleClass(function() {
          return $(this).is('.plus, .minus') ? 'plus minus' : 'plus';
        });
        target.parent().next().toggle();
      }

      // @ts-ignore
      $('span').click(showAnswers);

      function highlightSection(event) {
        const target = $(event.target);
        $('.focused').toggleClass('focused');
        target.toggleClass('focused');

        const answer = $('#' + target.parent().attr('class'));
        $('.highlight').toggleClass('highlight');
        answer.toggleClass('highlight');
        answer[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }

      $('li > h3 ').click(highlightSection);
    });

  }

}
