import {EventEmitter, Injectable} from '@angular/core';
import {ResultModel} from '../bar-graph/result.model';
import {ApsimModel} from './apsim.model';

@Injectable({providedIn: 'root'})
export class ApsimService {

  selectedOptions = new EventEmitter<{
    coverCrop: string,
    soilProfile: string,
    startMonth: string,
    startDay: string,
    terminationMonth: string,
    terminationDay: string
  }>();

  selectedMetFile = new EventEmitter<string>();

  apsimModels = new EventEmitter<ApsimModel[]>();

  resultModels = new EventEmitter<ResultModel[]>();

  loading = new EventEmitter<boolean>();

  compare = new EventEmitter<boolean>(false);

}
