import {Component, OnInit} from '@angular/core';
import * as d3 from 'd3';
import * as topojson from 'topojson';
import {FeatureCollection} from 'geojson';
import {ApsimService} from '../apsim/apsim.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-map-selector',
  templateUrl: './map-selector.component.html',
  styleUrls: ['./map-selector.component.css']
})
export class MapSelectorComponent implements OnInit {

  public loading: boolean;
  constructor(private apsimService: ApsimService) {
  }

  ngOnInit() {

    let selectedMetFile = 'none';
    this.loading = true;
    let compare = false;
    const metFiles = [
      ['Northwest Nebraska 43 103', 'North Central Nebraska 43 101', 'North Central Nebraska 43 99', 'Northeast Nebraska 43 97', ''],
      ['Southwest Nebraska 41 103', 'Southwest Nebraska 41 101', 'Central Nebraska 41 99', 'Southeast Nebraska 41 97',
        'Southeast Nebraska 41 95']
    ];

    const width = 800;
    const height = 450;
    let active = d3.select(null);
    let activeClimateTile = d3.select(null);
    const self = this;

    /* select the html element as the container of the visualization */
    const svg = d3.select('#mapview')
      .attr('viewBox', '0 0 800 430')
      .classed('svg-content-responsive', true);

    const path = d3.geoPath();
    const g = svg.append('g');

    /* Creating a promise to resolve the json parsing for the map */
    const promise = new Promise(resolve => {
      resolve(d3.json('https://covercrops.unl.edu/assets/data/map/us-state-counties.json'));
    });

    /* After the promise is resolved, execute the ready function with the resolved value */
    promise.then(value => {
      ready(value);
      this.loading = false;
    });

    /**
     * function for plotting the map .
     */
    function ready(us) {

      /* append path to all the counties */
      g.append('g')
        .attr('id', 'counties')
        .selectAll('path')
        .data((topojson.feature(us, us.objects.counties) as unknown as FeatureCollection).features)
        .enter().append('path')
        .attr('d', path)
        .attr('class', 'county-boundary');

      /* Display all the counties name*/
      g.selectAll('.labels')
        .data((topojson.feature(us, us.objects.counties) as unknown as FeatureCollection).features)
        .attr('class', 'labels')
        .enter().append('text')
        .attr('fill', 'darkblue')
        .attr('transform',  d => 'translate(' + path.centroid(d)[0] + ',' + path.centroid(d)[1] + ')')
        .attr('text-anchor', 'middle')
        .attr('font-size', '1.5px')
        .text(d => d.properties.name);

      /* append path to all the states */
      g.append('g')
        .attr('id', 'states')
        .selectAll('path')
        .data((topojson.feature(us, us.objects.states) as unknown as FeatureCollection).features)
        .enter().append('path')
        .attr('d', path)
        .attr('class', 'state')
        .on('click', displayInCounties);

      /* Display all the state name*/
      g.selectAll('.statelabel')
        .data((topojson.feature(us, us.objects.states) as unknown as FeatureCollection).features)
        .enter().append('text')
        .attr('fill', 'darkblue')
        .attr('transform',  d => 'translate(' + path.centroid(d)[0] + ',' + path.centroid(d)[1] + ')')
        .attr('text-anchor', 'middle')
        .attr('dy', '.25em')
        .attr('class', 'statelabel')
        .text(d => d.properties.name);

      g.append('path')
        .datum(topojson.mesh(us, us.objects.states, (a, b) => a !== b))
        .attr('id', 'state-borders')
        .attr('d', path);

      /* Starts by zooming into the state of Nebraska*/
      zoomIntoNebraska(us);

    }

    /**
     * Zoom Into the state of Nebraska
     */
    function zoomIntoNebraska(us: any) {

      const mapFeatureCollection = (topojson.feature(us, us.objects.states) as unknown as FeatureCollection);
      // tslint:disable-next-line:max-line-length
      const nebraskaPath = mapFeatureCollection.features.filter(d => d.properties.name === 'Nebraska')[0];  /* Filters out everything except Nebraska from the path */
      const bounds = path.bounds(nebraskaPath);

      const dx = bounds[1][0] - bounds[0][0];
      const dy = bounds[1][1] - bounds[0][1];
      const x = (bounds[0][0] + bounds[1][0]) / 2;
      const y = (bounds[0][1] + bounds[1][1]) / 2;
      const scale = .9 / Math.max(dx / width, dy / height);
      const translate = [width / 2 - scale * x, height / 2 - scale * y];

      /* transition of zooming in the state */
      g.transition()
        .style('stroke-width', 1.5 / scale + 'px')
        .attr('transform', 'translate(' + translate + ')scale(' + scale + ')');
    }

    /**
     * Get the appropriate climate || Meteorological File for the selected climate tile.
     */
    function getClimateFile() {
      if (!compare) {
        if (activeClimateTile.node() === this && activeClimateTile.classed('active')) {
          selectedMetFile = '';
          self.apsimService.selectedMetFile.emit(selectedMetFile);  /* Emits the event every time a climate file is clicked. */
          return activeClimateTile.classed('active', false);
        }
        activeClimateTile.classed('active', false);
        activeClimateTile = d3.select(this).classed('active', true);

        selectedMetFile = d3.select(this).attr('id');  /* Update the selection of the metFile */

        self.apsimService.selectedMetFile.emit(selectedMetFile);  /* Emits the event every time a climate file is clicked. */
      }
    }

    /**
     * Display the counties in Nebraska when clicked
     */
    function displayInCounties(d) {

      /* filtering in the state of Nebraska */
      if (d.properties.name === 'Nebraska') {

        /* Removes state's name from the map */
        g.selectAll('.statelabel').remove();

        active.classed('active', false);
        active = d3.select(this).classed('active', true);

        const bounds = path.bounds(d);
        const dx = bounds[1][0] - bounds[0][0];
        const dy = bounds[1][1] - bounds[0][1];
        const x = (bounds[0][0] + bounds[1][0]) / 2;
        const y = (bounds[0][1] + bounds[1][1]) / 2;
        const scale = 1 / Math.max(dx / width, dy / height);
        const translate = [width / 2 - scale * x, height / 2 - scale * y];

        /* Display climate tiles */
        const startPositionX = bounds[0][0];
        const startPositionY = bounds[0][1] - 27;
        const boxWidth = 34;
        const boxHeight = 50;
        for (let row = 0; row < 2; row++) {
          for (let col = 0; col < 5; col++) {
            g.append('rect')
              .attr('class', 'tiles')
              .attr('id', metFiles[row][col])
              .attr('x', startPositionX + col * boxWidth)
              .attr('y', startPositionY + row * boxHeight)
              .attr('width', boxWidth)
              .attr('height', boxHeight)
              .attr('fill-opacity', 0)
              .attr('stroke', 'gray')
              .attr('stroke-width', .1)
              .on('click', getClimateFile);

            /* Adding the xy text for every climate tile for better comprehension */
            g.append('text')
              .attr('x', startPositionX + col * boxWidth)
              .attr('y', startPositionY + row * boxHeight + boxHeight)
              .text(row + '' + col)
              .attr('font-size', 2);
          }
        }

        /* transition of zooming in the state */
        g.transition()
          .duration(750)
          .style('stroke-width', 1.5 / scale + 'px')
          .attr('transform', 'translate(' + translate + ')scale(' + scale + ')');
      }
    }

    this.apsimService.compare.subscribe((Compare: boolean) => {
      compare = Compare;
      if (compare) {
        $('.tiles').addClass('disabled');
      } else {
        $('.tiles').removeClass('disabled');
      }
    });
  }
}
